.section1{
    display: flex;
}
.section2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    align-self: stretch;
    height: 620px;
    opacity: 1;
    background-color: rgb(240, 240, 240);
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px;
    border: 0px;
}
.sectionTextContent{
    text-align: center;
    margin: auto;
    width: 50%;
    padding: 10px;
}
.pDiv{
    flex-grow: 1; margin: 0px; overflow: hidden; font-size: 20px; text-transform: none; text-align: left; letter-spacing: 0px; line-height: 26px; white-space: pre-line; opacity: 1; visibility: visible; font-family: Poppins;
    text-align: center;
}
.contactContainer{
    text-align: left;
    width: 50%;
}
.carouse-certificate{
    height: 100%;
}
.carouse-certificate > div{
    height: 100%;
}
.carouse-certificate .carouse-div {
    padding: 10%;
    background-color: rgb(0, 126, 40);
    color: white;
}
.infotextsym{
    text-align: left;
}
.infotextsym::before{
    text-align: left;
    color: white;
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 50px;
}
.infotext{
    text-align: left;
    line-height: 5px;
    font-size: 20px;
}
