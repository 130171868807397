.toolbar {
    position: fixed;
    top: 0;
    /* left: 0;
    right: 0; */
    /* height: 70px; */
    display: flex;
    align-items: center;
    /* background-color: #1976d2; */
    /* color: white; */
    font-weight: 600;
    background-color: white;
    z-index: 9999;
    width: -webkit-fill-available;
    /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.12); */
  }
  .optionsContainer{
      display: flex;
      padding-top: 5px;
      padding-bottom: 5px;
  }
  .optionDiv{
    top: 59px; left: 156px; width: 94px; height: 21px; z-index: 15;
    cursor: pointer;
  }
  .optionP{
    width: 94px; height: 21px; flex-grow: 1; margin: 0px; overflow: hidden; font-size: 22px; font-weight: 500; text-transform: none; text-align: left; letter-spacing: 0px; line-height: 21px; white-space: pre-line; opacity: 1; visibility: visible; font-family: Poppins;cursor: pointer;
  }
  .optionSpan{
    color: #030303ff
  }